/* eslint-disable react/jsx-key */
import React from "react"

//logo de empresas clientes
import Rimac from "@components/sharedComponents/customersLogos/logos/Rimac.svg"
import Culqui from "@components/sharedComponents/customersLogos/logos/Culqui.svg"
import Platzi from "@components/sharedComponents/customersLogos/logos/Platzi.svg"
import Unifef from "@components/sharedComponents/customersLogos/logos/Unifef.svg"
import Auna from "@components/sharedComponents/customersLogos/logos/Auna.svg"
import Alfin from "@components/sharedComponents/customersLogos/logos/Alfin.svg"
import Civa from "@components/sharedComponents/customersLogos/logos/Civa.svg"
import G4S from "@components/sharedComponents/customersLogos/logos/G4S.svg"
import Ibr from "@components/sharedComponents/customersLogos/logos/Ibr.svg"
import Conexo from "@components/sharedComponents/customersLogos/logos/Conexo.svg"
import Eriza from "@components/sharedComponents/customersLogos/logos/3eriza.svg"
import Astara from "@components/sharedComponents/customersLogos/logos/Astara.svg"

//imagen articulos blog
import article1 from "@components/sharedComponents/articleBlog/images/plataforma-score.jpg"
import article2 from "@components/sharedComponents/articleBlog/images/plataforma-ticker.jpg"
import article3 from "@components/sharedComponents/articleBlog/images/ia-conversacional.jpg"
import article4 from "@components/sharedComponents/articleBlog/images/software-cloud-pbx.jpg"
import article5 from "@components/sharedComponents/articleBlog/images/top-plataformas-omnicanales.jpg"

//iconos poteciamos operaciones
import atencion from "@components/pageHome/operations/images/imageOne.webp"
import televentas from "@components/pageHome/operations/images/imageTwo.webp"
import telemarketing from "@components/pageHome/operations/images/imageThree.webp"
import cobranzas from "@components/pageHome/operations/images/imageFour.webp"

// logos testimonios
import CulquiTes from "@components/sharedComponents/testimonies/images/logos/culqi.svg"
import NetcallTes from "@components/sharedComponents/testimonies/images/logos/netcall.svg"
import AvivaTes from "@components/sharedComponents/testimonies/images/logos/aviva.svg"
import CasaRosselloTes from "@components/sharedComponents/testimonies/images/logos/casaRossello.svg"
import AstaraTes from "@components/sharedComponents/testimonies/images/logos/astara.svg"

//foto testimonio desktop
import culqiDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/culqi.png"
import avivaDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/aviva.png"
import netcallDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/netcall.png"
import astaraDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/astara.png"
import casaRosselloDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/casaRossello.png"

//foto testimonio desktop
import culqiMobile from "@components/sharedComponents/testimonies/images/fotosMobile/culqi.png"
import avivaMobile from "@components/sharedComponents/testimonies/images/fotosMobile/aviva.png"
import netcallMobile from "@components/sharedComponents/testimonies/images/fotosMobile/netcall.png"
import astaraMobile from "@components/sharedComponents/testimonies/images/fotosMobile/astara.png"
import casaRosselloMobile from "@components/sharedComponents/testimonies/images/fotosMobile/casaRossello.png"


//image acordeon
import ImageOne from "@components/pageHome/images/accordionRight/imageOne.webp"
import ImageTwo from "@components/pageHome/images/accordionRight/imageTwo.webp"
import ImageThree from "@components/pageHome/images/accordionRight/imageThree.webp"
import ImageFour from "@components/pageHome/images/accordionRight/imageFour.webp"
import ImageFive from "@components/pageHome/images/accordionRight/imageFive.webp"

let data = {
  start: {
    textbody:
      "Hacemos que las empresas se contacten y comuniquen mejor con sus clientes, con soluciones omnicanales y tecnologías de voz. Aplicamos para todos, grandes operaciones de Contact Center y negocios que buscan escalar.",
    image: "./banner.html",
    textbutton: "Hablar con un experto",
    textlink: "Registrarme a una demo Beex",
    link: "/contact-center/",
  },
  customersDesktop: [
    Rimac,
    Culqui,
    Platzi,
    Unifef,
    Auna,
    Alfin,
    Civa,
    G4S,
    Ibr,
    Conexo,
    Eriza,
    Astara,
  ],
  customersTablet: {
    arrayOne: [Rimac, Culqui, Platzi, Unifef, Auna, Alfin],
    arrayTwo: [Civa, G4S, Ibr, Conexo, Eriza, Astara],
  },
  modules: [
    {
      tab: "BPOs",
      title: "Contact Center",
      img: atencion,
      link: "/contact-center/",
      text:
        "Implementa campañas salientes y entrantes en minutos. Ya sea por canal telefónico o digital, escala tus operaciones en minutos para la cantidad de agentes que necesites, en tiempo real.",
      colorTab: "#FFAB99",
      background: "#ffd5cc"
    },
    {
      tab: "Customer Experience",
      title: "Servicio al cliente",
      img: televentas,
      link: "/marketing-ventas/",
      text:
        "Interactúa con tus clientes por sus canales preferidos y potencia tu soporte con chatbots inteligentes. Mejora tu nivel de servicio con nuestras soluciones omnicanales.",
      colorTab: "#FFD96E",
      background: "#ffebb7"
    },
    {
      tab: "Conversion",
      title: "Marketing y ventas",
      img: telemarketing,
      text:
        "Automatiza tu gestión de leads y automatiza acciones de contacto con llamadas, WhatsApp y más. Mejora tu conversión potenciando tu comunicación con workflows e IA.",
      link: "/contact-center/",
      colorTab: "#AFC9F7",
      background: "#d7e4fb"
    },
    {
      tab: "Payment",
      title: "Cobros",
      img: cobranzas,
      link: "/software-cobranzas/",
      text:
        "Añade marcación predictiva, SMS e IVR a tus campañas de cobro. Llega más rápido y mejor, logrando aumentar tu contactabilidad solo con contactos efectivos.",
      colorTab: "#90ECF2",
      background: "#d3f7fa"
    },
  ],
  articles: [
    {
      image: article1,
      title: "Beex Contact Center: qué es y cómo funciona",
      textbody:
        "Descubre qué es esta plataforma y cómo puede elevar la productividad y la calidad de tus interacciones con tus clientes.",
      link: "https://beexcc.com/blog/plataforma-score/",
      card: true,
    },
    {
      image: article2,
      title: "¿Qué es Beex Conversations y cuáles son sus funciones?",
      textbody:
        "Conoce cómo funciona esta plataforma, y cómo puedes empezar a crear tu cuenta para ofrecer una buena experiencia omnicanal.",
      link: "https://beexcc.com/blog/plataforma-ticker/",
      card: true,
    },
    {
      image: article3,
      title: "Descubre qué es IA conversacional y las ventajas para tu empresa",
      textbody:
        "Conoce qué es la IA conversacional y cómo puede transformar tu negocio. ¡Entra aquí y descubre sus beneficios!",
      link: "https://beexcc.com/blog/ia-conversacional/",
    },
    {
      image: article4,
      title: "Top 10 de Software Cloud PBX para empresas en el 2024",
      textbody:
        "Eleva la eficiencia de tu Contact Center con esta selección de los 10 mejores Software Cloud PBX.",
      link: "https://beexcc.com/blog/software-cloud-pbx/ ",
    },
    {
      image: article5,
      title: "Top de plataformas omnicanales del 2024 para mejorar tu atención",
      textbody:
        "Obtén una ventaja competitiva con el top 8 de plataformas omnicanales para mejorar la experiencia de tus clientes en los canales de venta.",
      link: "https://beexcc.com/blog/top-plataformas-omnicanales/",
    },
  ],
  contacts: {
    title: "Empieza a mejorar la comunicación con tus clientes, ahora mismo",
    textbody:
      "Cuéntanos mejor sobre tu operación y te compartiremos feedback importante. Conoce cuál es la solución ideal para tu empresa y que resultados puedes lograr.",
    href: "/hablemos/",
    btn: "Quiero una asesoría gratuita",
  },
  footer: {
    product: "PRODUCTOS",
    productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
    productMobileOne: "Conversations",
    linkone: "/conversations/",
    productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
    productMobileTwo: "Contact Center",
    linktwo: "/contact-center/",
    productDesktopFour: "Validation: Software de validación de teléfono.",
    productMobileFour: "Validation",
    linkfour: "/validation/",

    solution: "SOLUCIONES",
    solutionOne: "Atención al cliente",
    linkfive: "/atencion-al-cliente/",
    solutionTwo: "Telemarketing",
    linksix: "/marketing-ventas/",
    solutionThree: "Contact Center",
    linkseven: "/contact-center/",
    solutionFour: "Cobranzas",
    linkeight: "/software-cobranzas/",
    solutionFive: "WhatsApp",
    linkwhatsapp: "/whatsapp-business-api/",
    linkchatbots: "/chatbots-online/",
    solutionSix: "Chatbots",
    linkinstagram: "/chatbot-instagram/",
    solutionSeven: "Instagram",
    linkwebchat: "/chat-en-vivo-web/",
    solutionEight: "WebChat",

    company: "EMPRESA",
    companyOne: "Nosotros",
    linknine: "/nosotros/",
    companyTwo: "Casos de éxito",
    linkten: "/casos-de-exito/",
    companyThree: "Blog",
    linkeleven: "https://beexcc.com/blog/",
    companyFour: "Hablemos",
    linktwelve: "/hablemos/",
    companySix: "Documentación",
    linkdocs: "https://beexcc.com/docs/",
    companyFive: "Recursos",
    linktthirteen: "/recursos/",

    blog: "BLOG",
    blogone: "¿Cómo elegir el mejor software para call center?",
    linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

    blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
    linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

    blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
    linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

    blogfour:
      "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
    linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

    blogfive: "9 características que las herramientas de telemarketing",
    linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

    masblog: "Conoce más sobre nuestro blog",
    linkmasblog: "https://beexcc.com/blog/",

    address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
    phone: "(+511) 277-4188",
    mail: "hi@beexcc.com",
    copyright: "Copyright© 2024 Beex Perú. Todos los derechos reservados.",
    privacyPolicy: "Política de Privacidad",
    privacyPolicyHref: "/politicas-privacidad",
    cookiesPolicy: "Política de Cookies",
    cookiesPolicyHref: "/politicas-cookies",
  },

  accordion: [
    {
      title: "Inteligencia Artifical",
      description: "<p> Mejora los tiempos de productividad de tus campañas y agentes gracias a la eficiencia operativa que brinda la inteligencia artificial en la comunicación bidireccional. <br /> <br /> Diseña y crea chatbots inteligentes en cuestión de minutos. Retroalimenta tus chatbots con información y ayuda a tus clientes con procesos de autogestión sin mayor esfuerzo. <br /> <br /> Gracias a nuestra integración con ChatGPT, automatiza tus procesos y logra mejorar tus métricas más importantes de satisfacción, marketing y ventas. </p>",
      image: ImageFive
    },
    {
      title: "Contact Center Omnicanal",
      description: "<p>Migra tu operación de atención al cliente, telemarketing, ventas o cobros, integrando tus canales más importantes de comunicación, como voz, WhatsApp, email, SMS y redes sociales. <br /> <br /> Configura tus campañas salientes o entrantes en minutos, automatizando acciones con IVR, chatbots o workflows por acciones potenciadas con IA. <br /> <br /> Administra tu operación total con nuestros módulos de CRM o intégrate con tu preferido. Accede a monitoreo, analítica y reportería en tiempo real para mejorar tus principales métricas. </p>",
      image: ImageOne
    },
    {
      title: "Workflows y automatizaciones",
      description: "<p> Diseña y crea flujos para automatizar acciones de contacto y comunicación, ya sea por canal de voz o mensajería, como WhatsApp, SMS o email. <br /> <br /> Implementa IVR o chatbots potenciados con IA para casos de servicio al cliente o marketing. Desde chatbots para FAQ o consultas, hasta casos de prospección inteligente para marketing y ventas. <br /> <br /> Diseña flujos de acciones automatizadas integradas con tu CRM, como Salesforce, HubSpot, etc. Automatiza tus notificaciones de compras, seguimiento de pedidos, confirmación de citas y mucho más. </p>",
      image: ImageFour
    },
    {
      title: "WhatsApp Business API",
      description: "<p> Suma el canal de más engagement y que mejores resultados de experiencia, marketing y ventas ofrece. <br /> <br /> Potencia WhatsApp con chatbots con IA, enrutamiento inteligente de casos y workflow de acciones automatizadas, como notificaciones en tiempo real y seguimiento de casos. <br /> <br /> Automatiza tu servicio al cliente, marketing y ventas, sumando la app de mejor open rate (98%) y conversión rate (0.1%) del mundo. </p>",
      image: ImageTwo
    },
    {
      title: "Soluciones de voz",
      description: "<p>Migra tu telefonía a la nube con nuestras centrales telefónicas o consume nuestra Voice API para crear tus propias aplicaciones. <br /> <br /> Mejora la contactabilidad con tus leads o clientes con marcaciones inteligentes que validen los números a los que llamas y que solo transfieran llamadas contestadas por humanos. <br /> <br /> Predice el resultado de tus llamadas en tiempo real e intégralo con tus aplicaciones favoritas a través de APIs.</p>",
      image: ImageThree,
    },
  ],
  testimonies: [
    {
      image: CulquiTes,
      title:
        "Con Beex, hemos logrado mejorar la contactabilidad con nuestros leads en +65%",
      textbody:
        "Culqi, la fintech que revoluciona la forma de hacer pagos digitales, usa Beex para automatizar sus procesos de telemarketing, por canal telefónico y WhatsApp.",
      name: "Paul Vera",
      post: "Jefe de Telemarketing",
      link: "/casos-de-exito/culqi/",
      imgDesktop: culqiDesktop,
      imgMobile: culqiMobile,
      metrics: [
        {
          metric: "+65%",
          descripcion: "mejoró su contactabilidad con sus leads",
          color: "#FFB800",
        },
        {
          metric: "x8",
          descripcion: "aumentó sus ventas mensuales",
          color: "#5831D8",
        },
        {
          metric: "x3",
          descripcion: "redujo el tiempo de recorrido de bases",
          color: "#00CA56",
        },
      ],
    },
    {
      image: NetcallTes,
      title:
        "Con Beex y su marcador predictivo, hemos duplicado nuestras ventas mensuales",
      textbody:
        "Netcall Perú, BPO especializado en brindar soluciones al sector telecomunicaciones, ha automatizado procesos operativos, como backoffice, según sus reglas de negocio.",
      name: "Humberto Chumán",
      post: "Jefe de Proyectos",
      link: "/casos-de-exito/netcall-peru/",
      imgDesktop: netcallDesktop,
      imgMobile: netcallMobile,
      metrics: [
        {
          metric: "+25%",
          descripcion: "aumentó su contactabilidad",
          color: "#FFB800",
        },
        {
          metric: "+20%",
          descripcion: "mejoró su tasa de ocupación",
          color: "#5831D8",
        },
        {
          metric: "x2",
          descripcion: "duplicaron sus ventas mensuales",
          color: "#00CA56",
        },
      ],
    },
    {
      image: AvivaTes,
      title: "Con Beex, hemos mejorado +30% el proceso de agenda de citas",
      textbody:
        "Clínica Aviva, una clínica de multiespecialidad con un staff de más de 200 colaboradores agilizó su gestión de citas gracias a WhatsApp API.",
      name: "Luis Ordinola",
      post: "Coordinador de Mejora de Procesos",
      link: "/casos-de-exito/aviva/",
      imgDesktop: avivaDesktop,
      imgMobile: avivaMobile,
      metrics: [
        {
          metric: "+30%",
          descripcion: "mejoró el tiempo de agenda de citas",
          color: "#FFB800",
        },
        {
          metric: "+20%",
          descripcion:
            "ahora representa WhatsApp como canal para agendar citas",
          color: "#5831D8",
        },
      ],
    },
    {
      image: CasaRosselloTes,
      title:
        "Con Beex, hemos aumentado en +75% nuestros leads desde canal digital",
      textbody:
        "Casa Rosselló, compañía con más de 150 años de historia en el sector acabados y construcción, prospecta de forma más óptima con WhatsApp API y livechat.",
      name: "Marlen Vega",
      post: "Jefa de Marketing",
      link: "/casos-de-exito/casa-rossello/",
      imgDesktop: casaRosselloDesktop,
      imgMobile: casaRosselloMobile,
      metrics: [
        {
          metric: "+75%",
          descripcion: "aumento de leads digitales",
          color: "#FFB800",
        },
        {
          metric: "+65%",
          descripcion: "aumento de recompra",
          color: "#5831D8",
        },
      ],
    },
    {
      image: AstaraTes,
      title:
        "Con Beex, triplicamos nuestras ventas y reducido el esfuerzo de remarketing en 93%",
      textbody:
        "Astara, concesionaria de automóviles con presencia en Latam, ha automatizado sus procesos de marketing y ventas para canal orgánico y de paga con WhatsApp API.",
      name: "Jonny Castillo",
      post: "Jefe Comercial",
      link: "/casos-de-exito/astara/",
      imgDesktop: astaraDesktop,
      imgMobile: astaraMobile,
      metrics: [
        {
          metric: "x3",
          descripcion: "aumentó sus ventas digitales",
          color: "#FFB800",
        },
        {
          metric: "93%",
          descripcion: "redujo su esfuerzo para remarketing",
          color: "#5831D8",
        },
        {
          metric: "80%",
          descripcion: "mejoró contactabilidad con leads",
          color: "#00CA56",
        },
      ],
    },
    {
      title: "conoce",
      metrics: [
      ],
    },
  ],
}

export default data
